import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
// import reportWebVitals from './reportWebVitals';
import RoutingComponent from './routes/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { google_tag, google_tag_manager } from './constant/config';
import CookieNotice from './component/CookieNotice';

const getCookieStatus = (cookies, name) => {
  const cookie = cookies.filter((element) => element.name === name);
  if (cookie && cookie.length === 1) {
    if (!cookie[0].editable) {
      return cookie[0].default;
    } else {
      return cookie[0].checked;
    }
  }
  return false;
};

const setConsent = (necessaryStatus, analyticsStatus, update) => {
  window.gtag('consent', 'update', {
    'functionality_storage': necessaryStatus ? 'granted' : 'denied',
    'personalization_storage': necessaryStatus ? 'granted' : 'denied',
    'security_storage': necessaryStatus ? 'granted' : 'denied',
    'analytics_storage': analyticsStatus ? 'granted' : 'denied'
  });
  if (update) {
    try {
      analyticsStatus ? window.clarity('consent') : null; // window.clarity('consent', false);
    } catch (error) {
      console.log(error);
    }
  }
};

const setCookieConsent = (cookies, update) => {
  const necessaryStatus = getCookieStatus(cookies, 'necessary');
  const analyticsStatus = getCookieStatus(cookies, 'analytics');
  setConsent(necessaryStatus, analyticsStatus, update);
}

const root = ReactDOM.createRoot(document.getElementById('developer-portal'));
root.render(<React.StrictMode>
  <Suspense>
    <Helmet>
      {/* <!-- Google Tag Manager --> */}
      {google_tag_manager ? <script>{`
        (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${google_tag_manager}');
  `}</script> : null}
      {/* <!-- End Google Tag Manager --> */}

      {/* <!-- Google tag (gtag.js) -->  */}
      {google_tag ? <script async src={`https://www.googletagmanager.com/gtag/js?id=${google_tag}`}></script> : null}
      {google_tag ? <script>{`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '${google_tag}');
        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'analytics_storage': 'denied',
          'functionality_storage': 'granted',
          'personalization_storage': 'granted',
          'security_storage': 'granted'
        });
    `}</script> : null}
      {/* <!-- End of Google tag (gtag.js) -->  */}

      {/* <!-- Microsoft Clarity IS Now removed as it is integrated via Google tag manager --> */}
      {/* <!-- Microsoft Clarity --> */}
      {/* {clarity_tag ? <script>{`
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${clarity_tag}");
    `}</script> : null} */}
      {/* <!-- End of Microsoft Clarity --> */}

    </Helmet>
    <RoutingComponent />
    <CookieNotice

      onSave={(cookies) => {
        // console.log(cookies);
        setCookieConsent(cookies, true);
      }}
      onInit={(cookies) => {
        setCookieConsent(cookies);
      }}
      acceptAllButtonText="Accept All"
      cookiePrefix="user-cookie-status-"
    >
      <h3>We value your privacy</h3>
      <p>We use cookies to enhance your browsing experience and analyze our traffic. By clicking &rdquo;Accept All&rdquo;, you consent to our use of cookies. You can also manage individual cookie preferences.</p>
    </CookieNotice>
  </Suspense>
</React.StrictMode>);
// reportWebVitals();
